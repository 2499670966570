import { useEffect, useState } from "react";
import {
    Box,
    Card,
    CardBody,
    CardFooter,
    Divider,
    Flex,
    Grid,
    GridItem,
    Heading,
    IconButton,
    Image,
    Popover,
    PopoverArrow,
    PopoverBody,
    PopoverContent,
    PopoverTrigger,
    Stack,
    Text,
    useColorMode
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { FaLinkedin, FaSpotify, FaTwitter } from "react-icons/fa";
import { MdOutlineEmail } from "react-icons/md";
import employmentInfo from "../../config/home/sachinEmployment";
import sachinImages from "../../config/home/sachinImages.json"
import "./home.css"

const EmploymentIcon = ({ place, ...props }) => {
    return (
        <Popover trigger="hover" placement="top" {...props}>
            <PopoverTrigger>
                <Image
                    cursor="pointer"
                    src={place.image}
                    boxSize="50px"
                    objectFit={"contain"}
                    transition="0.3s all"
                    _hover={{
                        transform: "translateY(-2px)"
                    }}
                />
            </PopoverTrigger>
            <PopoverContent minW='0' w="fit-content">
                <PopoverArrow />
                <PopoverBody>
                    <Flex direction="row" align="center" fontSize="sm">
                        <Link to={place.website} target="_blank">
                            <Text
                                fontWeight="bold"
                                textDecoration="underline"
                                color="#3f84e4"
                            >{place.name}</Text>
                        </Link>
                        &nbsp;-&nbsp;
                        <Text fontStyle="italic">{place.position}</Text>
                    </Flex>
                </PopoverBody>
            </PopoverContent>
        </Popover>
    )
}

const Home = () => {
    const { colorMode } = useColorMode();
    const employmentData = employmentInfo();

    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    
    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentImageIndex((prevIndex) => (prevIndex + 1) % sachinImages.length);
          }, 5000); // 5000ms = 5 seconds
      
          return () => clearInterval(intervalId);
    })
    

    return (
        <Box h="100%" w="100%" display="flex" alignItems="center" justifyContent="center">
            <Box h="100%" w={{ base: "100%", sm: "90%", md: "75%", lg: "60%" }} display="flex" flexDirection="column" alignItems="center" justifyContent="space-between" pt={"30px"}>
                <Flex w="100%" direction="column" gap={5}>
                    <Card
                        direction={{ base: 'column', sm: 'row' }}
                        overflow='hidden'
                        variant='outline'
                    >
                        <Image
                            objectFit='cover'
                            boxSize={{base: "155px", md: "175px" }}
                            src={sachinImages[currentImageIndex].src}
                            alt={sachinImages[currentImageIndex].alt}
                        />
                        <CardBody p={3} fontSize={{base: "xs", md: "sm" }}>
                            <Flex direction="column" w="100%" justify="space-between">
                                <Flex h="100%" direction="column" w="100%">
                                    <Flex direction="row" align="center" justify="space-between">
                                        <Heading flex="0 0 auto" size={{ base: "sm", md: "md" }}>Sachin Jaishankar</Heading>
                                        <Flex gap={2} w="100%" align="center" justify="end">
                                            <Link to="mailto:sachin@unstatiq.com" target="_blank">
                                                <IconButton
                                                    p={0}
                                                    variant="ghost"
                                                    size="2xs"
                                                    icon={<MdOutlineEmail />}
                                                    transition="0.2s all"
                                                    color="static.100"
                                                    _hover={{
                                                        color: "static.200"
                                                    }}
                                                    _active={{
                                                        bg: "none",
                                                        transform: "translateY(1px)"
                                                    }}
                                                />
                                            </Link>
                                            <Link to="https://www.linkedin.com/in/sachinjaishankar" target="_blank">
                                                <IconButton
                                                    p={0}
                                                    variant="ghost"
                                                    size="2xs"
                                                    icon={<FaLinkedin />}
                                                    transition="0.2s all"
                                                    color="static.100"
                                                    _hover={{
                                                        color: "static.200"
                                                    }}
                                                    _active={{
                                                        bg: "none",
                                                        transform: "translateY(1px)"
                                                    }}
                                                />
                                            </Link>
                                            <Link to="https://www.twitter.com/sachinjshankar" target="_blank">
                                                <IconButton
                                                    p={0}
                                                    variant="ghost"
                                                    size="2xs"
                                                    icon={<FaTwitter />}
                                                    transition="0.2s all"
                                                    color="static.100"
                                                    _hover={{
                                                        color: "static.200"
                                                    }}
                                                    _active={{
                                                        bg: "none",
                                                        transform: "translateY(1px)"
                                                    }}
                                                />
                                            </Link>
                                        </Flex>
                                    </Flex>
                                    <Text fontWeight="bold"><span style={{ fontWeight: "300" }}>
                                        Currently:</span> Building managed care contract review tooling
                                    </Text>
                                    <Text fontWeight="bold"><span style={{ fontWeight: "300" }}>
                                        Education:</span> Duke University, B.S. in Computer Science, B.S. in Neuroscience
                                    </Text>
                                    {/* <Text fontWeight="bold"><span style={{ fontWeight: "300" }}>Age:</span> 20s</Text>
                                    <Text fontWeight="bold"><span style={{ fontWeight: "300" }}>
                                        Current Location:</span> AZ
                                    </Text> */}
                                </Flex>
                            </Flex>
                        </CardBody>
                    </Card>
                    <Flex direction="column" gap={5}>
                        <Stack spacing={0}>
                            <Text fontSize="lg" fontWeight="600">
                                Hi, I'm Sachin.
                            </Text>
                            <Text fontSize="sm">Currently building the most curated and intuitive contract management and evaluation solution for healthcare providers.</Text>
                        </Stack>
                        <Stack>
                            <Text>
                                Things I've worked on:
                            </Text>
                            <Grid templateColumns="repeat(5, 1fr)" px={5} fontSize="sm">
                                <GridItem colSpan={2}>- Financial accounting</GridItem>
                                <GridItem>- ERPs</GridItem>
                                <GridItem>- 3D assets</GridItem>
                                <GridItem colSpan={2}>- Healthcare payments</GridItem>
                                <GridItem colSpan={3}>- Data enrichment/anomaly detection</GridItem>
                            </Grid>
                        </Stack>
                        {/* <Stack>
                            <Text>
                                Things I like:
                            </Text>
                            <Grid templateColumns="repeat(5, 1fr)" px={5} fontSize="sm">
                                <GridItem colSpan={5}>
                                    - Basketball
                                    (<span className="hashtag" style={{ color: "#E56020" }}>
                                        <Link to="https://www.nba.com/suns" target="_blank">#TheValley</Link>
                                    </span>
                                    , followed closely by&nbsp;
                                    <span className="hashtag" style={{ color: "#00539B" }}>
                                        <Link to="https://goduke.com/sports/mens-basketball" target="_blank">#TheBrotherhood</Link>
                                    </span>)
                                </GridItem>
                                <GridItem colSpan={4}>
                                    - Music (most genres, played electric guitar for 10+ years)
                                    <Link to="https://open.spotify.com/user/sjaishankar" target="_blank">
                                        <IconButton
                                            icon={<FaSpotify color="#1DB954" />}
                                            variant="ghost"
                                            size="xs"
                                            _hover={{
                                                transform: "translateY(-1px)"
                                            }}
                                            _active={{
                                                transform: "translateY(1px)"
                                            }}
                                        />
                                    </Link>
                                </GridItem>
                                <GridItem>- Friends</GridItem>
                                <GridItem colSpan={2}>- Day trips + Nature</GridItem>
                                <GridItem>- Bars</GridItem>
                                <GridItem>- Views</GridItem>
                                <GridItem>- Board games</GridItem>
                                <GridItem colSpan={5}>- Video Games (2K, FIFA, Fortnite, and maybe Warzone sorry I'm not a #realgamer)</GridItem>
                            </Grid>
                        </Stack> */}
                        <Stack w="100%">
                            <Flex direction="row" align="center" justify="space-between">
                               {employmentData.map((place, index) => (
                                    <EmploymentIcon key={place.name} place={place} />
                               ))} 
                            </Flex>
                        </Stack>
                    </Flex>
                </Flex>
                <Box h="45px" w="100%" fontSize="sm" display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                    <Text
                        fontSize="xs"
                        color={colorMode === "light" ? "gray.500" : "gray.600"}
                    >Things I might be working on right now:&nbsp;</Text>
                    <Flex h="20px" direction="row" gap={1}>
                        <Link to="https://www.unstatiq.com" target="_blank"><Text color="#FF4545">Unstatiq</Text></Link>
                        <Divider orientation="vertical" align="center" color="green" />
                        <Link to="/nba"><Text color="static.100">Basketball 🏀</Text></Link>
                    </Flex>
                </Box>
            </Box>
        </Box>
    )
}

export default Home;
