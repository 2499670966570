import { useColorMode } from "@chakra-ui/react"

const employmentInfo = () => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { colorMode } = useColorMode();
    
    return (
        [
            {
                "name": "Verily",
                "image": "/images/employment/verily.png",
                "start_date": "06/27/2022",
                "end_date": "01/27/2023",
                "position": "Software Engineer",
                "website": "https://www.verily.com"
            },
            {
                "name": "Cisco",
                "image": colorMode === "light" ? "https://brand-assets.security.cisco.com/cisco-light.svg" : "https://brand-assets.security.cisco.com/cisco-light.svg",
                "start_date": "06/27/2022",
                "end_date": "01/27/2023",
                "position": "Software Engineer Intern",
                "website": "https://www.cisco.com"
            },
            {
                "name": "Personalis",
                "image": "/images/employment/personalis.png",
                "start_date": "06/27/2022",
                "end_date": "01/27/2023",
                "position": "Software Intern",
                "website": "https://www.personalis.com"
            },
            {
                "name": "Maxar",
                "image": "/images/employment/maxar.png",
                "start_date": "06/27/2022",
                "end_date": "01/27/2023",
                "position": "Software Engineer Intern",
                "website": "https://www.maxar.com"
            },
            {
                "name": "McKinney",
                "image": "/images/employment/mckinney.png",
                "start_date": "06/27/2022",
                "end_date": "01/27/2023",
                "position": "Analytics Intern",
                "website": "https://www.mckinney.com"
            },
            {
                "name": "Chipotle",
                "image": "/images/employment/chipotle.png",
                "start_date": "06/27/2022",
                "end_date": "01/27/2023",
                "position": "Line Worker",
                "website": "https://www.chipotle.com"
            }
        ]
    )
}

export default employmentInfo;
