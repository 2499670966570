import {
    Box,
    Text,
    useColorMode
} from "@chakra-ui/react";
import { Link } from "react-router-dom";

const NBALoading = () => {
    const { colorMode } = useColorMode();

    return (
        <Box
            w="100%"
            h="100%"
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
        >
            <Text
                userSelect="none"
                color={colorMode === "dark" ? "static.100" : "inherit"}
            >
                Playing around with UI around NBA games/scorebugs/stats/analysis. Check back later (maybe)!
            </Text>
            <Link to="/">
                <Text
                    color={colorMode === "dark" ? "static.100" : "inherit"}
                    textDecoration="underline"
                    cursor="pointer"
                    transition="0.2s all"
                    _hover={{
                        transform: "translateY(-1px)"
                    }}
                    _active={{
                        transform: "translateY(1px)"
                    }}
                >
                    Go back home
                </Text>
            </Link>
        </Box>
    )
}

export default NBALoading;
