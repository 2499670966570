import { extendTheme } from "@chakra-ui/react";

const theme = extendTheme({
    fonts: {
        body: "Inter, sans-serif",
        heading: "Inter, sans-serif",
    },
    config: {
        initialColorMode: "system",
        useSystemColorMode: true,
    },
    colors: {
        primary: {
            100: "#4C4A77",
            200: "#3C3969",
            300: "#2C285B",
            400: "#1C174D",
            500: "#0C063F",
            600: "#050431",
            700: "#00022B",
            800: "#00001C",
            900: "#00000D",
        },
        secondary: {
            100: "#FFFFF0",
            200: "#FFFDE0",
            300: "#FFFBD0",
            400: "#FFF9BF",
            500: "#FFF7AF",
            600: "#FFF49E",
            700: "#FFF18E",
            800: "#FFEE7D",
            900: "#FFEB6D",
        },
        background: {
            100: "#F9F9F9",
            200: "#F2F2F2",
            300: "#EAEAEA",
            400: "#E1E1E1",
            500: "#D9D9D9",
            600: "#B3B3B3",
            700: "#8C8C8C",
            800: "#666666",
            900: "#404040",
        },
        bg: {
            100: "#FFF9F9",
            200: "#FFF2F2",
        },
        violation: {
            100: "#FDB3B9",
        },
        violationText: {
            100: "#F05252",
        },
        static: {
            50: "#7B84FD",
            100: "#9C9DFF",
            200: "#868CFE",
            300: "#9195FF",
            400: "#707DFC",
            500: "#6069F7",
            600: "#545BF2",
            700: "#4951ED",
            800: "#3E47E8",
            900: "#333DE3"
        }
    },
});

export default theme;
