import React, { useState } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  useNavigate,
} from 'react-router-dom';
import { Flex, Box, IconButton, useColorMode } from '@chakra-ui/react';
import { MdDarkMode, MdOutlineDarkMode } from 'react-icons/md'

// Global


// Components
import NBAHome from './components/nba/NBAHome'
import Home from './components/home/Home';
import NBALoading from './components/nba/NBALoading';


function App() {
  const { colorMode, toggleColorMode } = useColorMode();

  return (
    <Box h="calc(100vh)" boxSizing="border-box">
      <Box h="calc(100vh - 40px)" overflow="hidden">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/nba" element={<NBALoading />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </Box>
      <Box h="40px" px={5} display="flex" alignItems="center" justifyContent="end">
        <IconButton
          icon={colorMode === 'light' ?
            <MdOutlineDarkMode style={{ height: "20px", width: "20px" }} /> :
            <MdDarkMode style={{ height: "20px", width: "20px", color: "#9C9DFF" }} />
          }
          variant="ghost"
          onClick={toggleColorMode}
          _hover={{
            bg: "none",
            color: "static.400"
          }}
          transition="0.3s all"
          _active={{
            transform: "translateY(2px)"
          }}
        />
      </Box>
    </Box>
  );
}

export default App;
